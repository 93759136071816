import React, { useState, useEffect } from "react";
import { Route, Routes, Link } from "react-router-dom";
import POSWindow from "./components/POSWindow/POSWindow";
import Login from "./components/Login/Login";
import Groups from "./components/Groups/Groups";
import Party from "./components/Party/Party";
import Parties from "./components/Parties/Parties";
import DailyReports from "./components/DailyReports/DailyReports";
import EmployeeTable from "./components/EmployeeTable/EmployeeTable";
import GiftCardTable from "./components/GiftCardTable/GiftCardTable";
import MembershipVisitsTable from "./components/MembershipVisitsTable/MembershipVisitsTable";
import MembersTable from "./components/MembersTable/MembersTable";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./App.scss";
import bookImg from "./images/book.png";
import settingsPNG from "./images/settings.png";
import reportsPNG from "./images/reports.png";
import plus from "./images/bookPlus.png";
import giftcard from "./images/giftcard.png";
import logout from "./images/logout.png";
import pos from "./images/pos.png";
import menu from "./images/menu.png";
import member from "./images/members.png";
import member_visits from "./images/member_visits.png";
import employees from "./images/employees.png";
import { useIdleTimer } from "react-idle-timer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Settings from "./components/Settings/settings";
import { storeDirectoryHandle, getDirectoryHandle } from "./indexedDBUtils";
const stripePromise = loadStripe("pk_live_nrgG07O8aeKT6eajUXDAkwil");

interface User {
  name: string;
  pin: string;
  auth: string;
  firstname: string;
  lastname: string;
  middlename: string;
  nickname: string;
  admin: boolean;
  manager: boolean;
  front: boolean;
  kitchen: boolean;
}

const bffDays = [
  new Date("2025-01-20T12:00:00Z"),
  new Date("2025-02-01T12:00:00Z"),
  new Date("2025-03-30T12:00:00Z"),
  new Date("2025-04-18T12:00:00Z"),
  new Date("2025-05-26T12:00:00Z"),
  new Date("2024-06-05T12:00:00Z"),
  new Date("2024-06-14T12:00:00Z"),
  new Date("2024-06-24T12:00:00Z"),
  new Date("2024-07-03T12:00:00Z"),
  new Date("2024-07-12T12:00:00Z"),
  new Date("2024-07-29T12:00:00Z"),
  new Date("2024-08-01T12:00:00Z"),
  new Date("2024-08-07T12:00:00Z"),
  new Date("2024-08-25T12:00:00Z"),
  new Date("2024-09-02T12:00:00Z"),
  new Date("2024-10-05T12:00:00Z"),
  new Date("2024-11-27T12:00:00Z"),
  new Date("2024-12-27T12:00:00Z"),
];

export default function App(): JSX.Element {
  const getUserFromSessionStorage = (): User | null => {
    const savedUser = sessionStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  };

  const initialUser = getUserFromSessionStorage();

  const [user, setUser] = useState<User | null>(initialUser);
  const [directoryHandle, setDirectoryHandle] = useState(null);
  const [isBFF, setIsBFF] = useState(false);
  const isElectron = !!window.electronAPI;

  useEffect(() => {
    const today = new Date();
    const isBFFDay = bffDays.some(
      (bffDay) =>
        bffDay.getDate() === today.getDate() &&
        bffDay.getMonth() === today.getMonth() &&
        bffDay.getFullYear() === today.getFullYear()
    );
    setIsBFF(isBFFDay);
  }, []);

  useEffect(() => {
    if (!directoryHandle) {
      restoreDirectoryAccess();
    }
  }, [directoryHandle]); // Dependency on directoryHandle to avoid loop

  useEffect(() => {
    if (user) {
      sessionStorage.setItem("user", JSON.stringify(user));
    } else {
      sessionStorage.removeItem("user");
    }
  }, [user]);

  const handleDirectorySelected = async () => {
    if (isElectron) {
      try {
        // Use Electron dialog to select directory
        const directoryPath = await window.electronAPI.selectDirectory();
        if (directoryPath) {
          // Store the selected directory path
          setDirectoryHandle(directoryPath); // Assuming setDirectoryHandle can now work with paths in Electron context
        }
      } catch (error) {
        console.error("Error selecting directory in Electron:", error);
      }
    } else {
      try {
        const permissionDescriptor = { mode: "read" };
        if (
          (await directoryHandle.requestPermission(permissionDescriptor)) ===
          "granted"
        ) {
          await storeDirectoryHandle(directoryHandle);
        }
      } catch (error) {
        //$1("error getting directory handle");
      }
    }
  };

  const restoreDirectoryAccess = async () => {
    if (isElectron) {
      try {
        const directoryPath = await window.electronAPI.getStoredDirectoryPath();
        if (directoryPath) {
          // Optionally check if directory is accessible (might involve trying to read it)
          setDirectoryHandle(directoryPath); // Update to handle paths in Electron context
        } else {
          //$1("No directory path found in storage");
        }
      } catch (error) {
        console.error("Error restoring directory access in Electron:", error);
      }
    } else {
      try {
        const handle = await getDirectoryHandle();
        if (handle) {
          const permission = await handle.queryPermission({ mode: "read" });
          if (permission === "granted") {
            setDirectoryHandle(handle);
          } else {
            const requestedPermission = await handle.requestPermission({
              mode: "read",
            });
            if (requestedPermission === "granted") {
              setDirectoryHandle(handle);
            } else {
              setDirectoryHandle(null);
            }
          }
        } else {
          //$1("No directory handle found in IndexedDB");
        }
      } catch (error) {
        console.error("Error restoring directory access:", error);
      }
    }
  };

  const signout = (): void => {
    setUser(null);
    sessionStorage.setItem("token", null); // Store the token in sessionStorage
    const url = window.location.href.split("?")[0];
    window.location.href = url;
  };

  const handleOnIdle = (event: Event): void => {
    if (user && (user as User).name) {
      //console.log(`user ${(user as User).name} is idle`, event);
      //console.log('last active', getLastActiveTime());
      signout();
    }
  };

  const handleOnActive = (event: Event): void => {
    //console.log('user is active', event);
    //console.log('time remaining', getRemainingTime());
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60 * 3,
    onIdle: handleOnIdle,
    debounce: 500,
    onActive: handleOnActive,
  });

  const checkAuth = (type: string): boolean => {
    //console.log('check type match between',type,user?.auth)
    if (user && (user as User).auth.includes("admin")) {
      return true;
    } else if (user && (user as User).auth.includes(type)) {
      //console.log('match!')
      return true;
    } else {
      //console.log('nope...')
      return false;
    }
  };

  const displayAuth = (type: string): "default" | "none" => {
    if (user && (user as User).auth.includes("admin")) {
      return "default";
    } else if (user && !(user as User).auth.includes(type)) {
      return "none";
    } else {
      return "default";
    }
  };

  const handleMenu = (): void => {
    const navElement = document.getElementsByClassName("nav")[0];
    if (navElement.classList.contains("nav-open")) {
      navElement.classList.remove("nav-open");
      navElement.classList.add("nav-closed");
    } else {
      navElement.classList.remove("nav-closed");
      navElement.classList.add("nav-open");
    }
  };

  const closeMenu = (): void => {
    const navElement = document.getElementsByClassName("nav")[0];
    if (navElement.classList.contains("nav-open")) {
      navElement.classList.remove("nav-open");
      navElement.classList.add("nav-closed");
    }
  };

  const OpenMenu = (): JSX.Element => {
    //console.log('opening the menu!', document.getElementsByClassName("nav")[0]);
    setTimeout(() => {
      //console.log('opening the menu!', document.getElementsByClassName("nav")[0]);
      if (
        document.getElementsByClassName("nav")[0].classList.contains("nav-open")
      ) {
        //
      } else {
        document
          .getElementsByClassName("nav")[0]
          .classList.remove("nav-closed");
        document.getElementsByClassName("nav")[0].classList.add("nav-open");
      }
    }, 50);

    return <div></div>;
  };

  if (user && (user as User).name) {
    return (
      <div className="app">
        <Elements stripe={stripePromise}>
          <div className="nav no-print nav-closed">
            <span className="navlink">
              <span onClick={handleMenu}>
                <img src={menu} height="25" alt="" />
                <span className="navtext">Menu</span>
              </span>
            </span>
            <span
              className="navlink"
              style={{ display: user.front ? "default" : "none" }}
            >
              <Link to="pos" onClick={closeMenu}>
                <img src={pos} height="25" alt="" />
                <span className="navtext">Point of Sale</span>
              </Link>
            </span>
            <span
              className="navlink"
              style={{ display: user.manager ? "default" : "none" }}
            >
              <Link to="parties" onClick={closeMenu}>
                <img src={bookImg} height="25" alt="" />
                <span className="navtext">View Party Book</span>
              </Link>
            </span>
            <span
              className="navlink"
              style={{ display: user.manager ? "default" : "none" }}
            >
              <Link to="party" onClick={closeMenu}>
                <img src={plus} height="25" alt="" />
                <span className="navtext">Book a Party</span>
              </Link>
            </span>
            <span
              className="navlink"
              style={{ display: user.manager ? "default" : "none" }}
            >
              <Link to="groups" onClick={closeMenu}>
                <img src={plus} height="25" alt="" />
                <span className="navtext">Book a Group</span>
              </Link>
            </span>
            <span
              className="navlink"
              style={{ display: user.manager ? "default" : "none" }}
            >
              <Link to="daily-reports" onClick={closeMenu}>
                <img src={reportsPNG} height="25" alt="" />
                <span className="navtext">Daily Reports</span>
              </Link>
            </span>
            <span
              className="navlink"
              style={{ display: user.manager ? "default" : "none" }}
            >
              <Link to="employees" onClick={closeMenu}>
                <img src={employees} height="25" alt="" />
                <span className="navtext">Employees</span>
              </Link>
            </span>
            <span
              className="navlink"
              style={{ display: user.manager ? "default" : "none" }}
            >
              <Link to="gift-cards" onClick={closeMenu}>
                <img src={giftcard} height="25" alt="" />
                <span className="navtext">Gift Cards</span>
              </Link>
            </span>
            <span
              className="navlink"
              style={{ display: user.manager ? "default" : "none" }}
            >
              <Link to="members" onClick={closeMenu}>
                <img src={member} height="25" alt="" />
                <span className="navtext">Members</span>
              </Link>
            </span>
            <span
              className="navlink"
              style={{ display: user.manager ? "default" : "none" }}
            >
              <Link to="visits" onClick={closeMenu}>
                <img src={member_visits} height="25" alt="" />
                <span className="navtext">Membership Visits</span>
              </Link>
            </span>
            <span
              className="navlink"
              style={{ display: user.front ? "default" : "none" }}
            >
              <Link to="settings" onClick={closeMenu}>
                <img src={settingsPNG} height="25" alt="" />
                <span className="navtext">Settings</span>
              </Link>
            </span>
            <span className="navuser">
              <span onClick={signout}>
                <img src={logout} height="25" alt="" />
                <span className="navtext">Signout</span>
              </span>
            </span>
          </div>
          <Routes>
            <Route
              path="pos"
              element={
                user.front ? (
                  <POSWindow
                    user={user as User}
                    directoryHandle={directoryHandle}
                    isBFF={isBFF}
                  />
                ) : (
                  <OpenMenu />
                )
              }
            />
            <Route
              path="party"
              element={
                user.manager ? (
                  <Party user={user as User} setUser={setUser} />
                ) : (
                  <OpenMenu />
                )
              }
            />
            <Route
              path="parties"
              element={
                user.manager ? (
                  <Parties user={user as User} setUser={setUser} />
                ) : (
                  <OpenMenu />
                )
              }
            />
            <Route
              path="groups"
              element={
                user.manager ? (
                  <Groups user={user as User} setUser={setUser} />
                ) : (
                  <OpenMenu />
                )
              }
            />
            <Route
              path="daily-reports"
              element={user.manager ? <DailyReports /> : <OpenMenu />}
            />
            <Route
              path="employees"
              element={user.manager ? <EmployeeTable /> : <OpenMenu />}
            />
            <Route
              path="gift-cards"
              element={user.manager ? <GiftCardTable /> : <OpenMenu />}
            />
            <Route
              path="visits"
              element={user.manager ? <MembershipVisitsTable /> : <OpenMenu />}
            />
            <Route
              path="members"
              element={user.manager ? <MembersTable /> : <OpenMenu />}
            />
            <Route
              path="settings"
              element={
                <Settings
                  isBFF={isBFF}
                  setIsBFF={setIsBFF}
                  directoryHandle={directoryHandle}
                  setDirectoryHandle={setDirectoryHandle}
                  onDirectorySelected={handleDirectorySelected}
                />
              }
            />
            <Route
              path=""
              element={
                user.front ? (
                  <POSWindow
                    user={user as User}
                    directoryHandle={directoryHandle}
                    isBFF={isBFF}
                  />
                ) : (
                  <OpenMenu />
                )
              }
            />
          </Routes>
        </Elements>
        <ToastContainer />
      </div>
    );
  } else {
    return (
      <div className="app">
        <Login
          user={user as User}
          setUser={setUser}
          restoreDirectoryAccess={restoreDirectoryAccess}
        />
      </div>
    );
  }
}
