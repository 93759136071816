import React, { useState, useEffect } from 'react';
import './EmployeeTable.scss';

const EmployeeTable = () => {
    const [employees, setEmployees] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [editedEmployee, setEditedEmployee] = useState({});
    const [showRateHint, setShowRateHint] = useState(false);

    useEffect(() => {
        fetchEmployees();
    }, []);

    const positionMap = {
        1: '1) Ride Operator',
        2: '2) Front',
        3: '3) Kitchen',
        4: '4) Janitor',
        5: '5) Maintenance',
    };

    const calculateRateAmount = (rate) => 9.00 + (rate - 1) * 0.25;

    // Fetch all employees from the backend
    const fetchEmployees = async () => {
        try {
            const response = await fetch('/api/employees', {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                // Handle errors, possibly unauthorized access
                console.error('Failed to fetch employees');
                return;
            }

            const data = await response.json();
            setEmployees(data);
        } catch (error) {
            console.error('Error fetching employees:', error);
            // Handle error appropriately
        }
    };

    // Handle edit button click
    const handleEditClick = (employee) => {
        setEditingId(employee.id);
        setEditedEmployee(employee);
    };

    // Handle cancel button click
    const handleCancelClick = () => {
        setEditingId(null);
        setEditedEmployee({});
    };

    // Handle input changes in the editable row
    const handleInputChange = (e, field) => {
        let value;
        if (e.target.type === 'checkbox') {
            value = e.target.checked;
        } else if (e.target.type === 'number') {
            value = parseInt(e.target.value, 10);
        } else {
            value = e.target.value;
        }
        setEditedEmployee({
            ...editedEmployee,
            [field]: value,
        });
    };

    // Handle save button click
    const handleSaveClick = async () => {
        try {
            const response = await fetch(`/api/employee/${editingId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(editedEmployee),
            });

            if (!response.ok) {
                // Handle errors
                console.error('Failed to save employee');
                return;
            }

            const updatedEmployee = await response.json();

            // Update the employee in the state
            setEmployees(employees.map(emp => emp.id === editingId ? updatedEmployee : emp));
            setEditingId(null);
            setEditedEmployee({});
        } catch (error) {
            console.error('Error saving employee:', error);
            // Handle error appropriately
        }
    };

    return (
        <div className="table-container">
            <h1>Employee Management</h1>

            <table className="employee-table">
                <thead>
                    <tr>
                        {/* Table Headers */}
                        <th>ID</th>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Middlename</th>
                        <th>Nickname</th>
                        <th>Code</th>
                        <th>Email</th>
                        <th>Admin</th>
                        <th>Manager</th>
                        <th>Front</th>
                        <th>Kitchen</th>
                        <th>Food Discount</th>
                        <th>Discount</th>
                        <th>Position</th>
                        <th>Rate</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {employees.map(employee => {
                        const isEditing = employee.id === editingId;
                        return (
                            <tr key={employee.id} className={isEditing ? 'editing-row' : ''}>
                                <td>{employee.id}</td>
                                {isEditing ? (
                                    <>
                                        {/* Editable Fields */}
                                        <td>
                                            <input
                                                type="text"
                                                value={editedEmployee.firstname || ''}
                                                onChange={(e) => handleInputChange(e, 'firstname')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedEmployee.lastname || ''}
                                                onChange={(e) => handleInputChange(e, 'lastname')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedEmployee.middlename || ''}
                                                onChange={(e) => handleInputChange(e, 'middlename')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedEmployee.nickname || ''}
                                                onChange={(e) => handleInputChange(e, 'nickname')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedEmployee.code || ''}
                                                onChange={(e) => handleInputChange(e, 'code')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedEmployee.email || ''}
                                                onChange={(e) => handleInputChange(e, 'email')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={editedEmployee.admin || false}
                                                onChange={(e) => handleInputChange(e, 'admin')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={editedEmployee.manager || false}
                                                onChange={(e) => handleInputChange(e, 'manager')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={editedEmployee.front || false}
                                                onChange={(e) => handleInputChange(e, 'front')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={editedEmployee.kitchen || false}
                                                onChange={(e) => handleInputChange(e, 'kitchen')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                value={editedEmployee.fooddiscount || 0}
                                                onChange={(e) => handleInputChange(e, 'fooddiscount')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                value={editedEmployee.discount || 0}
                                                onChange={(e) => handleInputChange(e, 'discount')}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                value={editedEmployee.position || ''}
                                                onChange={(e) => handleInputChange(e, 'position')}
                                            >
                                                <option value="">Select Position</option>
                                                <option value="1">1. Ride Operator</option>
                                                <option value="2">2. Front</option>
                                                <option value="3">3. Kitchen</option>
                                                <option value="4">4. Janitor</option>
                                                <option value="5">5. Maintenance</option>
                                            </select>
                                        </td>
                                        <td className="rate-input-cell">
                                            <div className="rate-input-container">
                                                <input
                                                    type="number"
                                                    value={editedEmployee.rate || ''}
                                                    onChange={(e) => handleInputChange(e, 'rate')}
                                                    onFocus={() => setShowRateHint(true)}
                                                    onBlur={() => setShowRateHint(false)}
                                                />
                                                {editedEmployee.position === '3' && 
                                                    <div>
                                                        <p>Kitchen HS: 7</p>
                                                        <p>Kitchen grad: 15</p>
                                                    </div>
                                                }
                                                {(editedEmployee.position === '1' || editedEmployee.position === '4') && 
                                                    <div>
                                                        <p>HS: 1</p>
                                                        <p>Grad: 9</p>
                                                    </div>
                                                }

                                            </div>
                                        </td>
                                        <td>
                                            <button className="save-button" onClick={handleSaveClick}>Save</button>
                                            <button className="cancel-button" onClick={handleCancelClick}>Cancel</button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        {/* Read-Only Fields */}
                                        <td>{employee.firstname}</td>
                                        <td>{employee.lastname}</td>
                                        <td>{employee.middlename}</td>
                                        <td>{employee.nickname}</td>
                                        <td>{employee.code}</td>
                                        <td>{employee.email}</td>
                                        <td>{employee.admin ? 'Yes' : 'No'}</td>
                                        <td>{employee.manager ? 'Yes' : 'No'}</td>
                                        <td>{employee.front ? 'Yes' : 'No'}</td>
                                        <td>{employee.kitchen ? 'Yes' : 'No'}</td>
                                        <td>{employee.fooddiscount}</td>
                                        <td>{employee.discount}</td>
                                        <td>{positionMap[employee.position]}</td>
                                        <td>{employee.rate}</td>
                                        <td>
                                            <button className="edit-button" onClick={() => handleEditClick(employee)}>Edit</button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default EmployeeTable;
