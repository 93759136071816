import React, { useState, useEffect, useRef } from "react";
import "./POSWindow.scss";
import ButtonGrid from "../ButtonGrid/ButtonGrid";
import OrderPanel from "../OrderPanel/OrderPanel";
import CustomerPanel from "../CustomerPanel/CustomerPanel";
import PaymentButtons from "../PaymentButtons/PaymentButtons";
import * as Types from "./POSTypes";
import noPhoto from "./no_photo.png";
import {soundManager} from "./sounds/soundManager";
import { set } from "date-fns";


export default function POSWindow(props: Types.POSProps): JSX.Element {
  const [thisOrderItems, setThisOrderItems] = useState<
    Types.ExtendedLineItem[]
  >([]);
  const [lineItems, setLineItems] = useState<Types.ExtendedLineItem[]>([]);
  const [refunds, setRefunds] = useState<Types.Refund[]>([]);
  const [customer, setCustomer] = useState<Types.Customer>({});
  const [subId, setSubId] = useState<number[] | string[] | null>([]);
  const [orderNumber, setOrderNumber] = useState<number | null>(null);
  const [typedValue, setTypedValue] = useState("");
  const [address, setAddress] = useState<Types.Address>({});
  const [orderId, setOrderId] = useState<number>(0);
  //const [order,setOrder] = useState<Order>(null)
  const inputRef = useRef(null);
  const [taxExempt, setTaxExempt] = useState(false);
  const [transactions, setTransactions] = useState<Types.Transaction[]>([]);
  const [subtotalPrice, setSubtotalPrice] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [discountableSubtotal, setDiscountableSubtotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [taxLines, setTaxLines] = useState<Types.TaxLine[]>([]);
  const [fulfillmentOrderIds, setFulfillmentOrderIds] = useState([]);
  const [allFuls, setAllFuls] = useState<Types.Fulfillment[]>([]);
  const [, setIsLoadingFulfillments] = useState(false);
  const [fulOrders, setFulOrders] = useState<Types.FulfillmentOrder[]>([]);
  const [searchOrderIds, setSearchOrderIds] = useState([]);
  const [members, setMembers] = useState([]);
  const [orderSearchResults, setOrderSearchResults] = useState<Types.Order[]>(
    []
  );
  const [subs, setSubs] = useState<Types.Subscription[]>([]);
  const [currentSearch, setCurrentSearch] = useState<string | number | null>(
    null
  );
  const [loadingMessages, setLoadingMessages] = useState<string[]>([]);
  const [giftCards, setGiftCards] = useState<Types.GiftCard[]>([]);
  //const [searchHistory, setSearchHistory] = useState<(string | number)[]>([]);
  const [fulfilled, setFulfilled] = useState<string | null>(null);
  const [discountCodes, setDiscountCodes] = useState<Types.DiscountCode[]>([]);
  const [shopifyDiscountCodes, setShopifyDiscountCodes] = useState<
    Types.DiscountCode[]
  >([]);
  const [subtotalPreDiscount, setSubtotalPreDiscount] = useState(0);
  const [scannedItem, setScannedItem] = useState<Types.ExtendedLineItem | null>(
    null
  );
  const [triggerReset, setTriggerReset] = useState<number>(1);
  const [discountApplications, setDiscountApplications] = useState<
    Types.DiscountApplication[]
  >([]);
  const [totalTax, setTotalTax] = useState(0);
  const [changeAmount, setChangeAmount] = useState(0);
  const [isDelete, setIsDelete] = useState(false); // State to track the Delete key status
  const [reprintable, setReprintable] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [orderNotes, setOrderNotes] = useState<string>("");
  const [suggestedCustomers, setSuggestedCustomers] = useState<
    Types.Customer[]
  >([]);
  const [employeeResults, setEmployeeResults] = useState([]);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);

  const [attendanceCount, setAttendanceCount] = useState({});
  const [calendarAttendance, setCalendarAttendance] = useState({});
  const [refreshAttendance, setRefreshAttendance] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [isOrderCancelled, setIsOrderCancelled] = useState(false);
  const [emailReceipt, setEmailReceipt] = useState(false);


  const drawerOpenedRef = useRef(false);

  useEffect(() => {
    let timer;
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && !drawerOpenedRef.current) {
        //console.log("start esc timer");
        timer = setTimeout(() => {
          if (drawerOpenedRef.current) return;
          openDrawer();
          drawerOpenedRef.current = true;
        }, 500);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Escape" && drawerOpenedRef.current) {
        clearTimeout(timer);
        setTimeout(() => {
          drawerOpenedRef.current = false; // Reset the flag when the key is released
          //console.log("closing drawer!");
        }, 1000);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);


  useEffect(() => {
    fetch("/api/get-attendance", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        date: new Date(new Date().getTime() - 6 * 3600000).toISOString().slice(0, 10)
      }),
    })
    .then((response) => response.json())
    .then((data) => {

      type AttendanceObject = {
        id: number;
        category: string;
        timestamp: string;
        quantity: number;
        order_number: string;
        date: string;
        cancelled: boolean;
      }


      const attendenceObjects : AttendanceObject[] = data;

      const attCounts = {};

      attendenceObjects.forEach((attend) => {
        if(!attend.cancelled){
          const thisCategory = attend.category;
          attCounts[thisCategory] = (attCounts[thisCategory] || 0) + Number(attend.quantity);
        }
      })
      


      setAttendanceCount(attCounts);
      // Handle success - e.g., update UI or state
      fetch("/api/get-calendar-attendance", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          date: new Date(new Date().getTime() - 6 * 3600000).toISOString().slice(0, 10)
        }),
      })
      .then((response) => response.json())
      .then((data) => {
        //console.log('calendar attendance data:', data);
  
  
        const attendenceObjects : AttendanceObject[] = data;
  
        const attCounts = {};
  
        attendenceObjects.forEach((attend) => {
          if(!attend.cancelled){
            const thisCategory = attend.category;
            attCounts[thisCategory] = (attCounts[thisCategory] || 0) + Number(attend.quantity);
          }
        })
        
  
  
        setCalendarAttendance(attCounts);
        // Handle success - e.g., update UI or state
      })
      .catch((error) => {
        // Handle error - e.g., show error message to user
      });
    })
    .catch((error) => {
      // Handle error - e.g., show error message to user
    });
  }, [refreshAttendance,triggerReset,orderId]);
  
  useEffect(() => {
    setIsLoadingFulfillments(true);
    fetch(`/api/get-fulfillment-orders/${orderId}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const ids = data.fulfillment_orders.reduce((acc, order) => {
          if (order.status === "open" || order.status === "in_progress") {
            // Replace with the appropriate condition
            acc.push(order.id);
          }
          return acc;
        }, []);
        setFulfillmentOrderIds(ids);
        setFulOrders(data.fulfillment_orders);
        setIsLoadingFulfillments(false);
      })
      .catch((error) => {
        console.error("Error retrieving fulfillment orders: ", error);
        setIsLoadingFulfillments(false);
      });
  }, [orderId]); // Function to retrieve all fulfillment orders for a given order ID

  useEffect(() => {
    function isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    }

    const handleClick = (event) => {
      //("click!");
      if (
        event.target.tagName !== "INPUT" &&
        inputRef.current &&
        document.contains(inputRef.current) &&
        !event.target.closest(".paymentModal") // Check if the click was not within an element with the class "paymentModal"
      ) {
        inputRef.current.focus();
      }
    };

    if (!isMobileDevice()) {
      document.addEventListener("click", handleClick);
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      if (!isMobileDevice()) {
        document.removeEventListener("click", handleClick);
      }
    };
  }, []); //continuously sets the input to the search bar when not inputting elsewhere

  const openDrawer = () => {
    //console.log("opening drawer!");
    if (window.electronAPI) {
      soundManager.play('pop4');
      window.electronAPI.openDrawer();
    }
  };

  const clearOrder = (maintainButtons = false, open = true) => {
    setIsSubmitting(true);
    setEmailReceipt(false)
    setSubmissionMessage('Clearing Order...')
    setTypedValue("");
    setOrderSearchResults([]);
    //setMembers([]);
    if (open) openDrawer();
    //console.log("clearing...");
    soundManager.play('pop4');
    setChangeAmount(0);
    setOrderNumber(null);
    setRefunds([]);
    setReprintable(false);
    setCurrentSearch(null);
    setThisOrderItems([]);
    setCustomer({});
    setAddress({});
    setOrderId(0);
    setTaxExempt(false);
    setTransactions([]);
    setTaxLines([]);
    setFulfillmentOrderIds([]);
    setFulOrders([]);
    setAllFuls([]);
    setSearchOrderIds([]);
    setGiftCards([]);
    setSubId([]);
    setFulfilled(null);
    setDiscountApplications([]);
    setSuggestedCustomers([]);
    setDiscountCodes([]);
    setShopifyDiscountCodes([]);
    if (maintainButtons != true) {
      setMembers([]);
      setSubs([])
      setOrderSearchResults([]);
    }
    setTriggerReset((prev) => prev + 1);
    setTotalTax(0);
    setTotalTaxAmount(0);
    setOrderNotes("");
    setIsOrderCancelled(false)
    setIsSubmitting(false);
  };

  const sanitizeLineItem = (item: Types.ExtendedLineItem) => {
    const allowedProperties = {
      id: true,
      admin_graphql_api_id: true,
      fulfillable_quantity: true,
      fulfillment_service: true,
      fulfillment_status: true,
      gift_card: true,
      grams: true,
      name: true,
      price: true,
      product_exists: true,
      product_id: true,
      properties: true,
      quantity: true,
      requires_shipping: true,
      sku: true,
      taxable: true,
      title: true,
      total_discount: true,
      variant_id: true,
      variant_inventory_management: true,
      variant_title: true,
      vendor: true,
      tax_lines: true, // Added based on discrepancy noted
      duties: true, // Marked as 'any[]' in the type, but we'll represent as true here
      discount_allocations: true, // Same as duties
      price_set: {
        shop_money: {
          amount: true,
          currency_code: true,
        },
        presentment_money: {
          amount: true,
          currency_code: true,
        },
      },
      total_discount_set: {
        shop_money: {
          amount: true,
          currency_code: true,
        },
        presentment_money: {
          amount: true,
          currency_code: true,
        },
      },
    };

    const sanitizedItem = {} as Types.ExtendedLineItem;

    for (const prop in item) {
      if (Object.prototype.hasOwnProperty.call(allowedProperties, prop)) {
        if (
          typeof allowedProperties[prop] === "object" &&
          !Array.isArray(allowedProperties[prop])
        ) {
          sanitizedItem[prop] = sanitizeLineItem(item[prop]);
        } else {
          sanitizedItem[prop] = item[prop];
        }
      }
    }

    // Handle customAttributes to ensure no repeated keys
    if (sanitizedItem.properties) {
      const keyCounts: { [key: string]: number } = {};
      for (const attr of sanitizedItem.properties) {
        if (keyCounts[attr.name]) {
          keyCounts[attr.name]++;
          attr.name = `${attr.name}-${keyCounts[attr.name]}`;
        } else {
          keyCounts[attr.name] = 1;
        }
      }
    }

    if (sanitizedItem.taxable === undefined) sanitizedItem.taxable = true;
    if (sanitizedItem.requires_shipping === undefined)
      sanitizedItem.requires_shipping = false;

    return sanitizedItem;
  }; //take out unwanted attributes before submitting to API

  const undoFul = (itemId: number) => {
    setIsSubmitting(true);
    setSubmissionMessage('Cancelling Fulfillment...')
    const revFuls = [...allFuls].reverse();

    const ful = revFuls.find((ful) => {
      return ful.line_items.some((item) => {
        return item.id == itemId;
      });
    });

    if (ful?.id) {
      fetch(`/api/cancel-fulfill`, {
        method: "POST",
        body: JSON.stringify({
          fulId: `gid://shopify/Fulfillment/${ful.id}`,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {


          const unlimitedCount = ful.line_items.reduce((count, item) => {
            if (item.title.includes("Unlimited Wristband") && item.quantity > 0) {
              return count - item.quantity;
            } else if(item.title.includes("Fun Pack") && item.quantity > 0){
              return count - item.quantity*4;
            }
            return count;
          }, 0);
      
          //console.log('undo ful unlimited count: ', unlimitedCount);
      
          if(unlimitedCount<0){
            const attendanceObject={
              category:'Online',
              quantity: unlimitedCount,
              order_number: orderNumber,
              date: new Date(new Date().getTime() - 6 * 3600000).toISOString().slice(0, 10)
            }
            fetch("/api/add-attendance", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
              body: JSON.stringify(attendanceObject)
            })
            .then((response) => response.json())
            .then((data) => {
              setRefreshAttendance(prev=>prev+1)
              // Handle success - e.g., update UI or state
            })
            .catch((error) => {
              // Handle error - e.g., show error message to user
            });
          }



          updateOrder();
          clearOrder(false, false);
        })
        .catch((error) => console.error(error.message));
    }
  };

  const cancelAllFuls = async () => {
    setIsSubmitting(true);
    setSubmissionMessage('Cancelling Fulfillments...')
	let allFulsCompleted = false;
	await Promise.all(
	  allFuls.map(async (ful) => {
		try {
		  await fetch(`/api/cancel-fulfill`, {
			method: "POST",
			body: JSON.stringify({
			  fulId: `gid://shopify/Fulfillment/${ful.id}`,
			}),
			headers: {
			  "Content-type": "application/json; charset=UTF-8",
			  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
			},
		  });
		} catch (error) {
		  console.error(error.message);
		}
	  })
	);
	allFulsCompleted = true;
	return allFulsCompleted;
  };

  const addNotesToOrder = () => {
    if (!orderNotes || !orderId) return;
    fetch("/api/order-note", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ orderId: orderId, note: orderNotes }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          //console.log("Notes added successfully", data);
          // Update state or show success message if needed
        } else {
          console.error("Error adding notes:", data);
        }
      })
      .catch((error) => {
        console.error("Error adding notes:", error);
      });
  };

  const createOrder = (): void => {
    if (isSubmitting) {
      return; // Prevent multiple submissions
    }
    setIsSubmitting(true);
    setSubmissionMessage('Creating Order...');

    soundManager.play('chaching');
    let shopifyOrderItems = lineItems.filter(
      (item) =>
        !item.function ||
        (item.function !== "addMember" &&
          item.function !== "editMember")
    );

    const addMemberItems = lineItems.filter(
      (item) => item.function === "addMember"
    );
    const editMemberItems = lineItems.filter(
      (item) => item.function === "editMember"
    );
    const checkInItems = lineItems.filter(
      (item) => item.function === "checkIn"
    );
    const gcItems = lineItems.filter((item) => item.function === "redeemGC");
    const GCUWItems = lineItems.filter(
      (item) => item.function?.includes("activateGC_UW")
    );
    const GCEAP_Items = lineItems.filter(
      (item) => item.function?.includes("activateGC_EAP")
    );
    addMemberItems.forEach((item) => {
      const addMemberProperties = { membership_type: item.title };
      item.properties.forEach((property) => {
        addMemberProperties[property.name] = property.value;
      });
      //console.log("Creating member with properties:", addMemberProperties);

      // Construct the body of the request
      const requestBody = JSON.stringify(addMemberProperties);

      // Fetch request to create a new member via the API
      fetch("/api/create-member", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: requestBody,
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log("Member created:", data);
          // Handle success - e.g., update UI or state
        })
        .catch((error) => {
          console.error("Error creating member:", error);
          // Handle error - e.g., show error message to user
        });
    });
    editMemberItems.forEach((item) => {
      const editMemberProperties = {};
      item.properties.forEach((property) => {
        editMemberProperties[property.name] = property.value;
      });
      //console.log("editing member with properties:", editMemberProperties);

      // Construct the body of the request
      const requestBody = JSON.stringify(editMemberProperties);

      // Fetch request to create a new member via the API
      fetch("/api/edit-member", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: requestBody,
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log("Member edited:", data);
          // Handle success - e.g., update UI or state
        })
        .catch((error) => {
          console.error("Error editing member:", error);
          // Handle error - e.g., show error message to user
        });
    });
    checkInItems.forEach((item) => {
      const editMemberProperties = {
        visit_timestamp: new Date().toLocaleString(),
      };
      item.properties.forEach((property) => {
        editMemberProperties[property.name] = property.value;
      });
      console.log("checking in member...", editMemberProperties);
      // Construct the body of the request
      const requestBody = JSON.stringify(editMemberProperties);

      // Fetch request to create a new member via the API
      fetch("/api/checkin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: requestBody,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Member edited:", data);
          // Handle success - e.g., update UI or state
        })
        .catch((error) => {
          console.error("Error editing member:", error);
          // Handle error - e.g., show error message to user
        });
    });
    gcItems.forEach((item) => {
      const timestamp = new Date().toLocaleString();
      //console.log("redeeming GC...");
      const requestObject = {
        timestamp: timestamp,
        card_id: item.barcode,
      };
      const requestBody = JSON.stringify(requestObject);
      // Fetch request to create a new member via the API
      fetch("/api/redeem-gift-card", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: requestBody,
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log("GC Redeemed", data);
          
          // Handle success - e.g., update UI or state
        })
        .catch((error) => {
          console.error("Error redeeming GC:", error);
          // Handle error - e.g., show error message to user
        });
    });
    GCUWItems.forEach((item) => {
      const gcProperties = { items: "Unlimited Wristband" };
      item.properties.forEach((property) => {
        gcProperties[property.name] = property.value;
      });
      const requestBody = JSON.stringify(gcProperties);
      // Fetch request to create a new member via the API
      fetch("/api/activate-gift-card", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: requestBody,
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log("GC Activated", data);
          // Handle success - e.g., update UI or state
        })
        .catch((error) => {
          //console.error("Error Activating GC:", error);
          // Handle error - e.g., show error message to user
        });
    });
    GCEAP_Items.forEach((item) => {
      const gcProperties = {
        items: "Unlimited Wristband, Combo Meal Wristband",
      };
      item.properties.forEach((property) => {
        gcProperties[property.name] = property.value;
      });
      const requestBody = JSON.stringify(gcProperties);
      // Fetch request to create a new member via the API
      fetch("/api/activate-gift-card", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: requestBody,
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log("GC Activated", data);
          // Handle success - e.g., update UI or state
        })
        .catch((error) => {
          //console.error("Error Activating GC:", error);
          // Handle error - e.g., show error message to user
        });
    });

    if (shopifyOrderItems.length == 0) {
      clearOrder(false, false);
      return;
    }

    const shopifyDisountCodes: Types.DiscountCode[] = [...shopifyDiscountCodes];
    let shopifySubtotal = Number(subtotalPreDiscount.toFixed(2));
    const returnItems: Types.RefundLineItemNode[] = [];

    //for each line item in shopifyOrdedrItems, if the quantity is negative delete it and make a discount for the total amount then filter out the item
    shopifyOrderItems = shopifyOrderItems
      .map((item) => {
        if (item.quantity <= 0) {
          // Identify and process refund items with negative quantities for inventory adjustment
          if (item.variant_id) {
            // Prepare the body for the API request to adjust inventory
            const adjustmentBody = {
              variantId: item.variant_id,
              adjustment: Math.abs(item.quantity), // Since the quantity is negative, it will decrease the inventory
            };
            /*console.log(
              "Adjusting inventory for refund item and adjustment body:",
              item,
              adjustmentBody
            );*/
            // Make the API call to adjust the inventory
            fetch("/api/adjust-inventory", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
              body: JSON.stringify(adjustmentBody),
            })
              .then((response) => response.json())
              .then((data) => {
                //console.log("Inventory adjusted for refund:", data);
              })
              .catch((error) => {
                console.error("Error adjusting inventory:", error);
              });
          }

          returnItems.push({
            node: {
              id: Math.random(),
              quantity: Math.abs(item.quantity),
              lineItem: {
                title: item.title,
                vendor: item.vendor,
                price: item.price,
                quantity: Math.abs(item.quantity),
              },
              priceSet: {
                shopMoney: {
                  amount: item.price,
                },
              },
            },
          });
          shopifyDisountCodes.push({
            type: "fixed_amount",
            amount: Math.abs(item.quantity * item.price),
            code: `RETURN ${item.title} x ${Math.abs(item.quantity)}`,
          });

          shopifySubtotal += Number(
            Math.abs(item.quantity * item.price).toFixed(2)
          );
          //console.log("shopifySubtotal after", item, shopifySubtotal);
          //skip returning the item
          return;
        }
        return item;
      })
      .filter((item) => item !== undefined);

    //combine disount array into a single discount. add the amounts and combine the code strings
    const totalDiscount: Types.DiscountCode = {
      type: "fixed_amount",
      amount: 0,
      code: "",
    };

    //console.log("shopifyDisountCodes", shopifyDisountCodes);

    const refundTransactions: Types.Transaction[] = [];

    if (totalPrice > 0) {
      shopifyDisountCodes.forEach((discount) => {
        if (totalDiscount.code !== "") {
          totalDiscount.code += " & ";
        }
        if (discount.type == "fixed_amount") {
          totalDiscount.code += discount.code;
        } else if (discount.type == "percentage") {
          totalDiscount.code += discount.code;
        }
       // console.log("total discount after", discount, totalDiscount);
      });
      totalDiscount.amount += totalDiscountAmount;
      //console.log("totalDiscount", totalDiscount);
    } else {
      transactions.forEach((transaction) => {
        if (transaction.amount <= 0) {
          refundTransactions.push({ ...transaction, id: Math.random() });
        }
      });
      totalDiscount.code = "Refund";
    }

    //console.log("positiveTransactions", transactions);

    let positiveTransactions = [...transactions];

    positiveTransactions = positiveTransactions.filter(
      (transaction) => transaction.amount > 0
    );

    if (positiveTransactions.length == 0) {
      positiveTransactions.push(
        {
          amount: 0.01,
          kind: "sale",
          gateway: "na",
        },
        {
          amount: 0.01,
          kind: "change",
          gateway: "na",
        }
      );
    }
/*
    //filter out any "Tab" transactions
    positiveTransactions = positiveTransactions.filter(
      (transaction) => transaction.gateway !== "Tab"
    );
*/

    //const positiveTaxLines = taxLines.filter((taxLine) => taxLine.price > 0);

    const sanitizedLineItems = shopifyOrderItems.map(sanitizeLineItem);
    if (sanitizedLineItems.length == 0) {
      sanitizedLineItems.push({
        quantity: 1,
        price: 0,
        title: "N/A",
        requires_shipping: false,
      });
    }


    //console.log('creating attendance object')
    const attendanceObjects = [];
    lineItems.forEach((item) => {
      if(item.attendance_category){
        const attCat = item.attendance_category.split('*')[0];
        const attMult = Number(item.attendance_category.split('*')[1]||1);
        attendanceObjects.push({
          category:attCat,
          quantity: item.quantity*attMult,
          date: new Date(new Date().getTime() - 6 * 3600000).toLocaleDateString('en-US', { timeZone: 'America/Chicago' }),
        })
      }
    })

    //console.log('attendanceObjects', attendanceObjects);

    const calendarObjects = [];
    lineItems.forEach((item) => {
      if(item.calendar_category){
        const calCat = item.calendar_category.split('*')[0];
        const calMult = Number(item.calendar_category.split('*')[1]||1);
        calendarObjects.push({
          category:calCat,
          quantity: item.quantity*calMult,
          date: new Date(new Date().getTime() - 6 * 3600000).toLocaleDateString('en-US', { timeZone: 'America/Chicago' }),
        })
      }
    })



    const orderBody: Types.Order = {
      line_items: sanitizedLineItems,
      tax_exempt: taxExempt,
      transactions: positiveTransactions,
      total_price: Math.max(totalPrice, 0),
      subtotal_price: Math.max(Number(shopifySubtotal.toFixed(2)), 0),
      //tax_lines: positiveTaxLines,
      total_tax: Math.max(Number(totalTaxAmount.toFixed(2)), 0),
      discount_codes: [totalDiscount.amount > 0 ? totalDiscount : null],
      fulfillment_status: "fulfilled",
      inventory_behaviour: "decrement_ignoring_policy",
      send_receipt: emailReceipt,
      metafields: [
        {
          type: "json",
          namespace: "zdtpos",
          key: "return_items",
          value: JSON.stringify(returnItems),
        },
        {
          type: "json",
          namespace: "zdtpos",
          key: "refund_transactions",
          value: JSON.stringify(refundTransactions),
        },
        {
          type: "json",
          namespace: "zdtpos",
          key: "attendanceObjects",
          value: JSON.stringify(attendanceObjects),
        },
        {
          type: "json",
          namespace: "zdtpos",
          key: "calendarObjects",
          value: JSON.stringify(calendarObjects),
        }
      ],
      note: orderNotes,
    };
    //console.log("Order Body:", orderBody);

    // Conditionally include customer if it's not an empty object
    if (Object.keys(customer).length > 0) {
      orderBody.customer = customer;
    }

    // Conditionally include billing_address if it's not an empty object
    if (Object.keys(address).length > 0) {
      orderBody.billing_address = address;
    }

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customer.email)) {
      orderBody.email = customer.email;
    }

    let isCash = false;
    transactions.forEach((transaction) => {
      if (transaction.gateway == "Cash") {
        isCash = true;
      }
    });
    if (isCash || gcItems.length > 0) {
      openDrawer();
    }
    setSubmissionMessage('Submitting Order...');

    fetch(`/api/create-order`, {
      // Adjusted endpoint to create order
      method: "POST",
      body: JSON.stringify(orderBody),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.order) {
          lineItems.forEach((item) => {
            setSubmissionMessage('Adding Attendance...');

            if(item.attendance_category){
              const attCat = item.attendance_category.split('*')[0];
              const attMult = Number(item.attendance_category.split('*')[1]||1);
              const attendanceObject={
                category:attCat,
                quantity: item.quantity*attMult,
                order_number: response.order.order_number,
                date: response.order.processed_at,
              }
              fetch("/api/add-attendance", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
                body: JSON.stringify(attendanceObject)
              })
              .then((response) => response.json())
              .then((data) => {
                setRefreshAttendance(prev=>prev+1)
                // Handle success - e.g., update UI or state
              })
              .catch((error) => {
                // Handle error - e.g., show error message to user
              });
            }

            if(item.calendar_category){
              const calCat = item.calendar_category.split('*')[0];
              const calMult = Number(item.calendar_category.split('*')[1]||1);
              const calendarObject={
                category:calCat,
                quantity: item.quantity*calMult,
                order_number: response.order.order_number,
                date: response.order.processed_at,
              }
              fetch("/api/add-calendar-attendance", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
                body: JSON.stringify(calendarObject)
              })
              .then((response) => response.json())
              .then((data) => {
                setRefreshAttendance(prev=>prev+1)
                // Handle success - e.g., update UI or state
              })
              .catch((error) => {
                // Handle error - e.g., show error message to user
                setSubmissionMessage('Error adding calendar attendance')
                console.log('Error:', error);
              });
            }

          })

          if (window.electronAPI) {
            setSubmissionMessage('Printing Receipt...');

            if(Number(totalPrice)!=0||customer?.tags?.includes('employee')){
              setReprintable(true);
              window.electronAPI.printOrder(
                lineItems,
                transactions,
                response.order.order_number,
                response.order.id,
                Number(totalTaxAmount),
                shopifyDiscountCodes,
                Number(subtotalPrice),
                Number(totalPrice),
                customer
              );
            }
            //if transactions contains any tab payments, print again
            transactions.forEach((transaction) => {
              if (transaction.gateway == "Tab") {
                window.electronAPI.printOrder(
                  lineItems,
                  transactions,
                  response.order.order_number,
                  response.order.id,
                  Number(totalTaxAmount),
                  shopifyDiscountCodes,
                  Number(subtotalPrice),
                  Number(totalPrice),
                  customer
                );
                //break the for each loop
                return;
              }
            });

            //clearOrder();
            // Find the average price of all items in the order that are "Unlimited Wristband"s
            const foundItems = lineItems.filter((item) =>
              item.title?.includes("Unlimited Wristband")
            );
            const sum = foundItems.reduce((sum, item) => sum + item.price, 0);
            const averagePrice = sum / foundItems.length;
            const foundItem = { price: averagePrice };
            if (window.electronAPI && foundItem) {
              setSubmissionMessage('Creating upgrade discount...');
              fetch(`/api/create-membership-discount`, {
                method: "POST",
                body: JSON.stringify({
                  discountAmount: Math.round(foundItem.price * 100) / 100,
                  discountCode: response.order.order_number,
                }),
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
              })
                .then((res) => res.json())
                .then((discountResponse) => {
                 // console.log("Discount created:", discountResponse);
                })
                .catch((error) => {
                  console.error("Error creating discount:", error);
                });
            }
          }
          setOrder(response.order, true);
          setMembers([]);
          setSubs([]);
          setGiftCards([]);
          setOrderSearchResults([]);
          //console.log("set order: ", response.order);
        } else {
          alert(JSON.stringify(response.errors)); // Adjusted error handling
          setSubmissionMessage('Error with order.'+JSON.stringify(response.errors));
        }
      });
  }; //creates an order

  const fulfillAllOrders = (notify = true, printThisOrder = true) => {
    if (isSubmitting) {
      return; // Prevent multiple submissions
    }
    setIsSubmitting(true);
    setSubmissionMessage('Fulfilling Order...');
    const unlimitedCount = thisOrderItems.reduce((count, item) => {
      if (item.title.includes("Unlimited Wristband") && item.fulfillable_quantity > 0) {
        return count + item.fulfillable_quantity;
      } else if(item.title.includes("Fun Pack") && item.fulfillable_quantity > 0){
        return count + item.fulfillable_quantity*4;
      }
      return count;
    }, 0);

   // console.log('unlimited count: ', unlimitedCount);

    if(unlimitedCount>0){
      setSubmissionMessage('Adding up attendance changes...')
      const attendanceObject={
        category:'Online',
        quantity: unlimitedCount,
        order_number: orderNumber,
        date: new Date(new Date().getTime() - 6 * 3600000).toISOString().slice(0, 10)
      }
      fetch("/api/add-attendance", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(attendanceObject)
      })
      .then((response) => response.json())
      .then((data) => {
        setRefreshAttendance(prev=>prev+1)
        // Handle success - e.g., update UI or state
      })
      .catch((error) => {
        // Handle error - e.g., show error message to user
      });
    }

    setSubmissionMessage('Fulfilling All Items...');
    fetch(`/api/fulfillv2`, {
      method: "POST",
      body: JSON.stringify({
        input: {
          lineItems: fulfillmentOrderIds.map((id) => {
            return {
              fulfillmentOrderId: `gid://shopify/FulfillmentOrder/${id}`,
            };
          }),
          notify: notify,
        },
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSubmissionMessage('Checking upgrade options...');
        // Find the average price of all items in the order that are "Unlimited Wristband"s
        const foundItems = lineItems.filter((item) =>
          item.title?.includes("Unlimited Wristband")
        );
        const sum = foundItems.reduce((sum, item) => sum + item.price, 0);
        const averagePrice = sum / foundItems.length;
        const foundItem = { price: averagePrice };
        if (foundItem) {
          setSubmissionMessage('Creating upgrade discount...');
                fetch(`/api/create-membership-discount`, {
                  method: "POST",
                  body: JSON.stringify({
                    discountAmount: foundItem.price,
                    discountCode: orderNumber.toString(),
                  }),
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                  },
                })
                  .then((res) => res.json())
                  .then((discountResponse) => {
                    //console.log("Discount created:", discountResponse);
                  })
                  .catch((error) => {
                    //console.error("Error creating discount:", error);
                  });
        }
        if (window.electronAPI) {
          setReprintable(true);
          if (printThisOrder) {
            setSubmissionMessage('Printing receipt...');
            window.electronAPI.printOrder(
              lineItems,
              transactions,
              orderNumber,
              orderId,
              Number(totalTaxAmount),
              shopifyDiscountCodes,
              Number(subtotalPrice),
              Number(totalPrice),
              customer
            );
          }
          
        }
        // Handle success (e.g., show a success message)
        clearOrder(false, false);
        updateOrder();
      })
      .catch((error) => {
        console.error("Error fulfilling orders: ", error);
        setSubmissionMessage('An error occurred during fulfillment. Please cancel and try again.'+error);
        // Handle error (e.g., show an error message)
      });
  };

  function fulfillWithOptions(
    fulLineItems: { 
      fulfillmentOrderId: string; 
      fulfillmentOrderLineItems: {
         id: string; 
         quantity: number 
      }[] 
    }[],
    notify = true,
    printThisOrder = true
  ) {


    setIsSubmitting(true)
    setSubmissionMessage('Fulfilling Items...');
    const items = []

   thisOrderItems.forEach((item) => {
    //console.log('checking item: ', item)
      //for each of the fulLineItems, look at the id, find the line_item_id, and see if it matches this item.id
      fulLineItems.forEach((fulLineItem) => {
        fulLineItem.fulfillmentOrderLineItems.forEach((fulOrderLineItem) => {
          const thisId = Number(fulOrderLineItem.id.split('/').pop());
          fulOrders.forEach((fulOrder) => {
            fulOrder.line_items.forEach((lineItem) => {
              if (lineItem.id === thisId && lineItem.line_item_id === item.id) {
                const itemToAdd = {
                  title: item.title,
                  quantity: fulOrderLineItem.quantity
                }
                items.push(itemToAdd)
              }
            })
          })
        })
      })
    });

    const unlimitedCount = items.reduce((count, item) => {
      if (item.title.includes("Unlimited Wristband") && item.quantity > 0) {
        return count + item.quantity;
      } else if(item.title.includes("Fun Pack") && item.quantity > 0){
        return count + item.quantity*4;
      }
      return count;
    }, 0);


    if(unlimitedCount>0){
      setSubmissionMessage('Adding attendance...');
      const attendanceObject={
        category:'Online',
        quantity: unlimitedCount,
        order_number: orderNumber,
        date: new Date(new Date().getTime() - 6 * 3600000).toISOString().slice(0, 10)
      }
      fetch("/api/add-attendance", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(attendanceObject)
      })
      .then((response) => response.json())
      .then((data) => {
        setRefreshAttendance(prev=>prev+1)
        // Handle success - e.g., update UI or state
      })
      .catch((error) => {
        // Handle error - e.g., show error message to user
      });
    }


    setSubmissionMessage('Fulfilling items...');
    fetch(`/api/fulfillv2`, {
      method: "POST",
      body: JSON.stringify({
        input: {
          lineItems: fulLineItems,
          notify: notify,
        },
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // Find the average price of all items in the order that are "Unlimited Wristband"s
        const foundItems = lineItems.filter((item) =>
          item.title?.includes("Unlimited Wristband")
        );
        const sum = foundItems.reduce((sum, item) => sum + item.price, 0);
        const averagePrice = sum / foundItems.length;
        const foundItem = { price: averagePrice };
        if (foundItem) {
          setSubmissionMessage('Creating discount...');
                fetch(`/api/create-membership-discount`, {
                  method: "POST",
                  body: JSON.stringify({
                    discountAmount: foundItem.price,
                    discountCode: orderNumber.toString(),
                  }),
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                  },
                })
                  .then((res) => res.json())
                  .then((discountResponse) => {
                    //console.log("Discount created:", discountResponse);
                  })
                  .catch((error) => {
                    console.error("Error creating discount:", error);
                  });
        }
        if (window.electronAPI) {
          setReprintable(true);
          if (printThisOrder) {
            setSubmissionMessage('Printing Receipt...');

            window.electronAPI.printOrder(
              lineItems,
              transactions,
              orderNumber,
              orderId,
              Number(totalTaxAmount),
              shopifyDiscountCodes,
              Number(subtotalPrice),
              Number(totalPrice),
              customer
            );
          }
          
        }
        // Handle success (e.g., show a success message)
        clearOrder(false, false);
        updateOrder();
      })
      .catch((error) => {
        console.error("Error fulfilling orders: ", error);
        setSubmissionMessage('Error Fulfiling Orders. Cancel and try again...');
        // Handle error (e.g., show an error message)
      });
  }

  //update existing order information
  const updateOrder = () => {
    setSubmissionMessage('Updating order...');
    setTriggerReset((prev) => prev + 1);
    setRefreshAttendance(prev=>prev+1);
    const searchOrderIdDirectly = (searchTerm) => {
      return fetch(`/api/search-order/${searchTerm}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((response: { orders: Types.Order[] }) => {
          // Explicitly type the response
          if (response.orders) {
            setIsSubmitting(false);
            return response.orders;
          } else {
            throw new Error("Order not found");
          }
        });
    };


    searchOrderIdDirectly(orderId)
      .then((orders: Types.Order[]) => {
        setOrderSearchResults([...orders]);
        if (orders.length == 1 && thisOrderItems.length === 0) {
          setOrder(orders[0]);
        }
      })
      .catch((error) => {
        console.error("Error searching orders: ", error);
      });
  };

  const searchOrders = (searchTerm: string | number) => {
    // if the search term is a string and contains 'id=123' then strip that url param out and change the searchTerm to 123, also break it by ? and & for the params
    if (typeof searchTerm === "string" && searchTerm.includes("id=")) {
      const urlParams = new URLSearchParams(searchTerm);
      searchTerm = urlParams.get("id");
    }

    const isOrderId = false 

      
    //if (!maintain) clearOrder();
    const searchOrderIdDirectly = (searchTerm) => {
      return fetch(`/api/search-order/${searchTerm}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((response: { orders: Types.Order[] }) => {
          // Explicitly type the response
          if (response.orders) {
            return response.orders;
          } else {
            throw new Error("Order not found");
          }
        });
    };

    const performSearch = () => {
      if (isOrderId) {
        return searchOrderIdDirectly(searchTerm).then((order) => order);
      } else {
        return fetch(`/api/order-id-search/`, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            term: searchTerm,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            const edges = data?.data?.orders?.edges;
            if (edges && edges.length > 0) {
              const orders = edges.map((edge) => edge.node);
              const orderIds = orders
                .slice(0, 100)
                .map((order) => order.id.split("/").slice(-1)[0]);
              return searchOrderIdDirectly(orderIds.join(","));
            } else {
              throw new Error("No orders found");
            }
          });
      }
    };

    performSearch()
      .then((orders: Types.Order[]) => {
        setOrderSearchResults([...orders]);
        console.log("compare: ", searchTerm, orders[0].id, orders[0].order_number);
        const matchedOrder = orders.find(
          (order) =>
            order.id === Number(searchTerm) ||
            order.order_number === Number(searchTerm)
        );
        if (matchedOrder && (orderId || thisOrderItems.length === 0)) {
          console.log("matched order: ", matchedOrder,'members: ',members);
          setOrder(matchedOrder);
        }
      })
      .catch((error) => {
        console.error("Error searching orders: ", error);
      });
  };
  const searchByBarcode = async (barcode: string | number) => {
    try {
      const response = await fetch(`/api/search-by-barcode/${barcode}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      if (data) {
        setScannedItem({ ...data, isMod: false, modClass: null });
        setTypedValue("");
        return true; // Indicate that an item was found and added
      }
    } catch (error) {
      console.error("Error searching item by barcode:", error);
    }
    return false; // Indicate no item was found
  };

  const setOrder = (order: Types.Order, justSubmitted?: boolean) => {
    setIsSubmitting(true);
    setSubmissionMessage('Setting Order on POS...');
    try {
      setTriggerReset((prev) => prev + 1);
      order.refunds && setRefunds(order.refunds);
      order.total_tax && setTotalTaxAmount(Number(order.total_tax));
      order.id && setOrderId(order.id);
      order.order_number && setOrderNumber(order.order_number);
      if (order.transactions) {
        setTransactions(order.transactions);
      } else {
        setTransactions([{
          amount:
            (order.current_total_price || 0) - (order.total_outstanding || 0),
          kind: "sale",
          status: "success",
          gateway: "Paid",
        }]);
      }
      setFulfilled(order.fulfillment_status || null);
      order.line_items && setThisOrderItems(order.line_items);
      order.customer && setCustomer(order.customer);
      order.billing_address && setAddress(order.billing_address);
      setTypedValue("");
      order.current_subtotal_price &&
        setSubtotalPrice(order.current_subtotal_price);
      order.current_total_price && setTotalPrice(order.current_total_price);
      order.tax_lines && setTaxLines(order.tax_lines);
      order.fulfillments && setAllFuls(order.fulfillments);
      if (order.tags) {
        const apidtag = order.tags.split(",").filter((tag) => {
          return tag.includes("apid:");
        });
        const apids = apidtag.map((apid) => apid.split("apid:")[1]);
        const apid = apids[0] || null;
        setSubId(apids);
        if (apid) {
          searchMembers(apid, false, true);
        }
      }
      order.discount_codes && setShopifyDiscountCodes(order.discount_codes);
      setOrderNotes(order.note);
      order.cancelled_at && setIsOrderCancelled(true);
    } catch (error) {
      console.error("Error in setOrder:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const createMember = async (memberData) => {
    try {
      const response = await fetch("/api/create-member", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(memberData), // convert the member data to JSON
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const newMember = await response.json();
      //console.log("Member created:", newMember);
      // You can do something with the new member data, like updating state or UI
    } catch (error) {
      console.error("Error creating member:", error);
    }

    // Example usage:
    // createMember({
    //   name: "John Doe",
    //   membership_type: "Premium",
    //   dob: "1990-01-01",
    //   sub_id: 12345,
    //   photo: "base64EncodedStringOfPhoto", // assuming the photo is base64 encoded
    //   barcode: 67890
    // });
  };

  const searchMembers = (
    searchTerm: string | number,
    force = false,
    apid = false
  ) => {
    if (searchTerm == currentSearch && force != true) return;
    setCurrentSearch(searchTerm);
    //setSearchHistory((prev) => [searchTerm, ...prev]);
    setTypedValue("");
    console.log('searching members for: ', searchTerm);
    const isBarcode =
      typeof searchTerm === "number" ||
      (typeof searchTerm === "string" && /^\d+$/.test(searchTerm));

    const isEmail = typeof searchTerm === "string" && /^\S+@\S+\.\S+$/.test(
      searchTerm
    )

    const findAndDisplayFile = async (
      directoryHandle,
      fileName
    ): Promise<string | null> => {
      console.log('looking for : ', `${directoryHandle}/${fileName}`);
      try {
        if (window.electronAPI) {
          console.log('eletron looking for : ', `${directoryHandle}/${fileName}`);
          // Electron environment: Use the Electron method to read the file as a Data URL.
          // Assuming `directoryHandle` in Electron context is actually the directory path
          const filePath = `${directoryHandle}/${fileName}`; // This path construction might need adjustment based on your actual directory path handling
          return await window.electronAPI.readFileAsDataURL(filePath);
        } else if ("showDirectoryPicker" in window) {
          // Web environment: Use the File System Access API.
          console.log('web looking for : ', `${directoryHandle}/${fileName}`);
          const fileHandle = await directoryHandle.getFileHandle(fileName, {
            create: false,
          });
          if (fileHandle) {
            const file = await fileHandle.getFile();
            return URL.createObjectURL(file);
          }
        } else {
          console.warn("Your environment does not support file system access");
        }
      } catch (error) {
        console.error("Error accessing file:", error);
      }
      return null;
    };

    const fetchMemberPhotos = async (members) => {
      const updatedMembers = await Promise.all(
        members.map(async (member) => {
          const membershipNumberPadded = member.membership_number
            .toString()
            .padStart(6, "0");
          const photoFileName = `${membershipNumberPadded}.jpg`;

          try {
            let photoDataUrl = noPhoto; // Default to a no-photo placeholder

            //console.log("directoryHandle:", props.directoryHandle);
            const localPhotoUrl = await findAndDisplayFile(
              props.directoryHandle,
              photoFileName
            );
            console.log("localPhotoUrl:", photoFileName);
            if (localPhotoUrl) {
              console.log("Found local photo:", photoFileName);
              photoDataUrl = localPhotoUrl; // Use local photo if found
            } else {
              // Fetch from API if not found locally
              console.log('No local photo found, fetching from API for: ', membershipNumberPadded);
              const photoUrl = `/api/get-member-photo?membership_number=${membershipNumberPadded}`;
              const response = await fetch(photoUrl, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
              });

              if (response.ok) {
                const blob = await response.blob();
                photoDataUrl = URL.createObjectURL(blob);
              }
            }

            return { ...member, photo: photoDataUrl };
          } catch (error) {
            //console.error("Error fetching member photo:", error);
            return { ...member, photo: noPhoto };
          }
        })
      );
      return updatedMembers;
    };

    const fetchAllMemberVisits = async (members) => {
      const membershipNumbers = members
        .map((member) => member.membership_number)
        .join(",");
      if (membershipNumbers.length == 0) return members;
      try {
        const visitsResponse = await fetch(
          `/api/get-visits?membership_numbers=${membershipNumbers}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        const visitsData = await visitsResponse.json();
        // Process and attach visits to their respective members
        const membersWithVisits = members.map((member) => ({
          ...member,
          visits_array: visitsData
            .filter(
              (visit) => visit.membership_number === member.membership_number
            )
            .map((visit) => visit.visit_timestamp), // Extract only the timestamp from each visit
        }));

        return membersWithVisits;
      } catch (error) {
        console.error("Error fetching member visits:", error);
        return members.map((member) => ({ ...member, visits: [] })); // Assume no visits if there's an error
      }
    };

    const fetchSubscriptions = async (members) => {
      const uniqueSubIds = new Set(
        members.map((member) => member.sub_id).filter((subId) => subId)
      );
      if (subId?.length > 0) {
        subId.forEach((sub) => {
          uniqueSubIds.add(sub);
        });
      }
      const subscriptions = [];
	  if(members.length > 0){
		setCurrentTab(7);
	  } else{
		setCurrentTab(6);
	  }
      const loadingMsg = "Loading membership subscription details...";
      for (const subsId of uniqueSubIds) {
        try {
          addLoadingMessage(loadingMsg);
          const res = await fetch(`/api/get-subscription-details/${subsId}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          });
          const data = await res.json();
          subscriptions.push(data.payload);
          removeLoadingMessage(loadingMsg);

          //setCurrentTab(7)
        } catch (error) {
          console.error("Error fetching subscription details:", error);
          removeLoadingMessage(loadingMsg);
        }
      }
      if (subs != subscriptions) {
        setSubs(subscriptions); // Assuming setSubs is your state update function for subs
      }
    };

    const searchMemberships = async (queryParam, queryValue) => {
      const encodedValue = encodeURIComponent(queryValue);
      const loadingMsg = "Loading membership details and photos...";
      try {
        addLoadingMessage(loadingMsg);
        const detailedMembershipsResponse = await fetch(
          `/api/memberships-details?${queryParam}=${encodedValue}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        const detailedMembershipsData =
          await detailedMembershipsResponse.json();
        setSubs([]);
        setMembers(detailedMembershipsData || [null]);
        fetchSubscriptions(detailedMembershipsData);
        fetchAllMemberVisits(detailedMembershipsData);

        //fetch photos
        const membersWithPhotos = await fetchMemberPhotos(
          detailedMembershipsData
        );
        const membersWithVisits = await fetchAllMemberVisits(membersWithPhotos); // Fetch and attach visits
        removeLoadingMessage(loadingMsg);
        setMembers(membersWithVisits);
      } catch (err) {
        removeLoadingMessage(loadingMsg);
        console.error("Error:", err);
      }
    };
    if (isBarcode) {
      searchMemberships("barcode", searchTerm);
    } else if(isEmail){
      console.log('searching email',searchTerm)
      searchMemberships("email", searchTerm);
    } else {
      searchMemberships("name", searchTerm);
    }
  };

  const searchBarcodes = (barcode) => {
    return new Promise((resolve) => {
      searchByBarcode(barcode).then((found) => {
        if (found) {
          setTypedValue("");
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  };

  const searchGCs = (searchTerm: string | number, force = false) => {
    return new Promise((resolve) => {
      if (searchTerm == currentSearch && force != true) {
        resolve(false);
        return;
      }
  
      const isBarcode =
        typeof searchTerm === "number" ||
        (typeof searchTerm === "string" && /^\d+$/.test(searchTerm));
  
      if (isBarcode) {
        fetch(`/api/search-gift-cards?card_number=${searchTerm}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
          .then((gcResponse) => gcResponse.json())
          .then((gcData: Types.GiftCard[]) => {
            console.log("gcData", gcData);
  
            let foundValidGC = false;
  
            for (const gc of gcData) {
              if (
                !gc.redeem_timestamp &&
                (!gc.expiration || new Date(gc.expiration) >= new Date()) &&
                (!gc.valid_starting || new Date(gc.valid_starting) <= new Date())
              ) {
                console.log('found valid gc:', gc);
  
                const existingGC = lineItems.find(
                  (item) => item.barcode === gc.card_id
                );
  
                if (!existingGC) {
                  console.log('gc not found in lineitems');
  
                  const newItem = {
                    title: "Redeem Gift Card",
                    price: 0,
                    quantity: 1,
                    max_quantity: 1,
                    barcode: gc.card_id,
                    properties: [
                      {
                        name: "Items",
                        value: gc.items,
                      },
                      ...(gc.expiration
                        ? [
                            {
                              name: "Expiration",
                              value: new Date(gc.expiration).toLocaleDateString(),
                            },
                          ]
                        : []),
                      {
                        name: "Card Number",
                        value: gc.card_number.toString(),
                      },
                      ...(gc.issued_to
                        ? [
                            {
                              name: "Issued To",
                              value: gc.issued_to.toString(),
                            },
                          ]
                        : []),
                    ],
                    function: "redeemGC",
                    attendance_category: "Gift Card",
                  };
  
                  if (orderId) {
                    clearOrder(true, false);
                    setThisOrderItems([newItem]);
                  } else {
                    setThisOrderItems((prev) => [...prev, newItem]);
                  }
  
                  foundValidGC = true;
                  break; // Stop once we find and redeem a valid gift card
                }
              }
            }
            setGiftCards(gcData);
            if (foundValidGC) {
              resolve(true);
            } else if (gcData.length > 0) {
             resolve(true);
             setCurrentTab(9); 
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.error("Error fetching gift cards:", error);
            resolve(false);
          });
      } else {
        resolve(false);
      }
    });
  };
  

  const addLoadingMessage = (message: string) => {
    setLoadingMessages((prevMessages) => [...prevMessages, message]);
  };

  const removeLoadingMessage = (message: string) => {
    setLoadingMessages((prevMessages) =>
      prevMessages.filter((msg) => msg !== message)
    );
  };

  const handleKeyDown = (event) => {
    if (isDelete && (event.key === "Delete" || event.key === "Backspace")) {
      event.preventDefault(); // Prevent the backspace key from functioning
    }
    if (event.key === "Enter" && typedValue) {
      const searchValue = typedValue+'';
      setTypedValue("");
      searchGCs(searchValue).then((foundGC) => {
        if (!foundGC) {
          searchBarcodes(searchValue).then((foundBarcode) => {
            if (!foundBarcode) {
              //console.log("no GCs or barcodes found");
              searchMembers(searchValue);
              searchOrders(searchValue);
            }
          });
        } else {
          setTypedValue("");
        }
      });
    }
  };

  //search history saved for autocomplete
  /* 
  useEffect(() => {
    setFilteredSearches(
      searchHistory
        .filter(
          (search) =>
            typeof search === "string" &&
            search.toLowerCase().includes(typedValue.toLowerCase())
        )
        .slice(0, 3) // Limit to three results
    );
  }, [typedValue, searchHistory]);

  const [filteredSearches, setFilteredSearches] = useState([]);
*/

  return (
    <div key="window" className="poswindow">
      <div className="buttonheader">
        <h3 style={{ marginTop: '0.5vw', marginBottom: '0.5vw' }}>ZDT's Point of Sale</h3>
        {loadingMessages.length > 0 ? (
          <span className="loadingmsgs">{loadingMessages[0]}</span>
        ) : (
        <>
          {Object.entries(attendanceCount)
            .sort(([, a], [, b]) => (b as number) - (a as number))
            .map(([key, value]) => (
              <span 
                key={key} 
                className="cashiername"
                onClick={() => setRefreshAttendance(prev => prev + 1)}
              >
                {key}: {value as number} &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            ))}
            <br/>
            {Object.entries(calendarAttendance)
            .sort(([, a], [, b]) => (b as number) - (a as number))
            .map(([key, value]) => (
              <span 
                key={key} 
                className="cashiername"
                onClick={() => setRefreshAttendance(prev => prev + 1)}
              >
                {key}: {value as number} &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            ))}
        </>
        )}
        <br />
        <input
          ref={inputRef}
          className="posInput"
          value={typedValue}
          onChange={(e) => setTypedValue(e.target.value)}
          onKeyDown={handleKeyDown}
          list="prev-searches"
        />
        {/* <datalist id="prev-searches">
          {filteredSearches.map((search, index) => (
            <option key={index} value={search} />
          ))}
        </datalist> */}
        <button
          className="viewall"
          onClick={(_e) => {
            searchOrders(typedValue);
            setCurrentTab(6);
          }}
        >
          Orders
        </button>
        <button className="viewall" onClick={(_e) => searchMembers(typedValue)}>
          Members
        </button>
      </div>
      <div className={"buttonpanel " + (orderId && "hidebuttonpanel")}>
        <ButtonGrid
          thisOrderItems={thisOrderItems}
          setThisOrderItems={setThisOrderItems}
          typedValue={typedValue}
          setTypedValue={setTypedValue}
          orderId={orderId}
          members={members}
          setMembers={setMembers}
          orderSearchResults={orderSearchResults}
          setOrder={setOrder}
          searchMembers={searchMembers}
          clearOrder={clearOrder}
          subs={subs}
          subId={subId}
          giftCards={giftCards}
          orderNumber={orderNumber}
          isBFF={props.isBFF}
          scannedItem={scannedItem}
          setScannedItem={setScannedItem}
          triggerReset={triggerReset}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          discountCodes={discountCodes}
          setDiscountCodes={setDiscountCodes}
          customer={customer}
          setCustomer={setCustomer}
        />
      </div>
      <div className="orderheader">
        <CustomerPanel
          customer={customer}
          setCustomer={setCustomer}
          address={address}
          setAddress={setAddress}
          discountCodes={discountCodes}
          setDiscountCodes={setDiscountCodes}
          taxExempt={taxExempt}
          setTaxExempt={setTaxExempt}
          orderNumber={orderNumber}
          clearOrder={clearOrder}
          suggestedCustomers={suggestedCustomers}
          setSuggestedCustomers={setSuggestedCustomers}
          employeeResults={employeeResults}
          setEmployeeResults={setEmployeeResults}
          typedValue={typedValue}
          setTypedValue={setTypedValue}
          emailReceipt={emailReceipt}
          setEmailReceipt={setEmailReceipt}
        />
      </div>
      <div
        className={
          "receipt " +
          (orderId && "bigreceipt") +
          (fulfilled == "fulfilled" && "fulfilled")
        }
      >
        <OrderPanel
          thisOrderItems={thisOrderItems}
          setThisOrderItems={setThisOrderItems}
          orderNumber={orderNumber}
          subtotalPrice={subtotalPrice}
          setSubtotalPrice={setSubtotalPrice}
          setDiscountableSubtotal={setDiscountableSubtotal}
          discountableSubtotal={discountableSubtotal}
          taxLines={taxLines}
          setTaxLines={setTaxLines}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
          totalTax={totalTax}
          transactions={transactions}
          setTransactions={setTransactions}
          orderId={orderId}
          clearOrder={clearOrder}
          undoFul={undoFul}
          fulfilled={fulfilled}
          refunds={refunds}
          setRefunds={setRefunds}
          setTriggerReset={setTriggerReset}
          triggerReset={triggerReset}
          discountApplications={discountApplications}
          discountCodes={discountCodes}
          taxExempt={customer.tax_exempt}
          subtotalPreDiscount={subtotalPreDiscount}
          setSubtotalPreDiscount={setSubtotalPreDiscount}
          orderNotes={orderNotes}
          setOrderNotes={setOrderNotes}
          totalDiscountAmount={totalDiscountAmount}
          setTotalDiscountAmount={setTotalDiscountAmount}
          lineItems={lineItems}
          setLineItems={setLineItems}
          shopifyDiscountCodes={shopifyDiscountCodes}
          setShopifyDiscountCodes={setShopifyDiscountCodes}
          totalTaxAmount={totalTaxAmount}
          setTotalTaxAmount={setTotalTaxAmount}
		      fulfillments={allFuls}
          isOrderCancelled={isOrderCancelled}
        />
      </div>
      <PaymentButtons
        createOrder={createOrder}
        transactions={transactions}
        setTransactions={setTransactions}
        subTotalPrice={subtotalPrice}
        setSubtotalPrice={setSubtotalPrice}
        taxLines={taxLines}
        setTaxLines={setTaxLines}
        totalPrice={totalPrice}
        setTotalPrice={setTotalPrice}
        orderId={orderId}
        fulfillAllOrders={fulfillAllOrders}
        fulfilled={fulfilled}
        discountCodes={discountCodes}
        setDiscountCodes={setDiscountCodes}
        changeAmount={changeAmount}
        setChangeAmount={setChangeAmount}
        typedValue={typedValue}
        setTypedValue={setTypedValue}
        reprintable={reprintable}
        clearOrder={clearOrder}
        orderNotes={orderNotes}
        setOrderNotes={setOrderNotes}
        addNotesToOrder={addNotesToOrder}
		    updateOrder={updateOrder}
		    cancelAllFuls={cancelAllFuls}
        fulOrders={fulOrders}
        fulfillWithOptions={fulfillWithOptions}
        lineItems={lineItems}
        orderNumber={orderNumber}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        setSubmissionMessage={setSubmissionMessage}
        customer={customer}
      />
            {isSubmitting && (
        <div className="overlay">
          <div className="submission-message">
            <p>{submissionMessage}</p>
            <button className="cancel-button" onClick={e=>setIsSubmitting(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
